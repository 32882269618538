export function isChattingEnabled() {
    if(document.getElementById('userlike-chat')) return false;
    if(!process.env.REACT_APP_USERLIKE_WIDGET_KEY) return false;

    if(process.env.NODE_ENV === 'development') {
        console.log('Chat has issues with DEV environment (strict-origin-when-cross-origin) - DISABLED');
        return false;
    }

    // FOR DEV CHAT IS ALWAYS ENABLED
    return true;

    // eslint-disable-next-line
    let now = new Date();

    let chattingTimeRange = ['14', '17']; // Give the leading hour
    let chattingDayRange = [1,2,3,4]; // 0 is Sunday, 1 is Monday, ...

    let dayNumber = now.getDay();

    if(chattingDayRange.includes(dayNumber)) {
        let timeHour = now.getHours();
        return timeHour >= chattingTimeRange[0] && timeHour <= chattingTimeRange[1];
    }

    return false;
}

export function validateEmail(email) {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function validatePhone(phone) {
    return phone.match(
        /^([0-9]{3,4})[- /]?([0-9]{3})[- ]?([0-9]{3,4})$/
    );
}

export function validatePhonePrefix(prefix) {
    return prefix.match(
        /^(\+)([0-9]{2})$/
    );
}
