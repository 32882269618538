// https://reactdatepicker.com/
// https://github.com/Hacker0x01/react-datepicker

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useState, useEffect} from "react";
import Loading from "./Loading";
import TimeCard from "./TimeCard";
import {useTranslation} from "react-i18next";
import {getButtonIconByShortCode} from "../utilities/icons";
import {useCookies} from "react-cookie";


export default function Calender({dates, onSelectDate, onSelectTime, resetConsultant}) {
    const [t] = useTranslation('common');
    const [cookies] = useCookies(['lang']);

    const [isLoading, setIsLoading] = useState(true);
    const [groupByDayDates, setGroupedByDayDates] = useState([]);
    const [calenderDates, setCalenderDates] = useState([]);
    const [availableTimes, setAvailableTimes] = useState(null);

    const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            return result;
        }, {});
    };

    useEffect(() => {
        let dateList = [];
        let itemList = [];

        dates.forEach((date, i) => {
            let typeList = [];

            let typeString = date.subject.split('_')[1];

            if(typeString === undefined) {
                // P = IN PERSON
                // V = VIDEO
                // T = TELEPHONE
                // C = CHAT
                typeList = ['P','V','T','C']
            } else {
                typeList = typeString.split('-')
            }

            let item = {
                id: date.id,
                startDateTime: date.start,
                types: typeList
            }

            let day = new Date(item.startDateTime);
            let endTimeDate = new Date(date.end);

            item.day = day.toISOString().split('T')[0];
            item.startTime = day.toTimeString().substr(0, 5);
            item.endTime = endTimeDate.toTimeString().substr(0, 5);

            dateList.push(day);
            itemList.push(item);
        });

        itemList.sort((a, b) => {
            return new Date(a.startDateTime) - new Date(b.startDateTime);
        });
        setGroupedByDayDates(groupBy(itemList, 'day'));
        setCalenderDates(dateList);

        setIsLoading(false);
    }, [dates]);

    const onChange = async (date) => {
        await chooseCalenderDate(date);
    };

    async function chooseCalenderDate(date) {
        let d = date.toLocaleDateString("sv-SE", { // you can use undefined as first argument
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })

        setAvailableTimes(groupByDayDates[d]);
        await onSelectDate(date);
    }

    async function handleSelectTime(e, time) {
        await onSelectTime(e, time);
    }

    if(isLoading) return <Loading />

    if(calenderDates.length === 0) {
        return(
            <div className="a-c">
                <h1>{t('appointment.myDates')}</h1>
                <div>{t('appointment.noDatesavailable')}</div>
                <button onClick={resetConsultant}>{t('appointment.choosesomeoneelse')}</button>
            </div>
        )
    }

    return (
        <section className="section-basic section-basic__w-md a-c">
            <h2>{t('appointment.myDates')}</h2>
            <DatePicker
                locale={cookies.lang ? cookies.lang.short : 'en'}
                onChange={(date) => onChange(date)}
                inline
                includeDates={calenderDates}
                dateFormat="yyyy/MM/dd"
                selected={new Date()}
            />
            <div className="timeslots-container">
                {
                    availableTimes &&
                        availableTimes.map((time, i) => {
                            return <TimeCard
                                key={i}
                                startTime={time.startTime}
                                endTime={time.endTime}
                                types={time.types}
                                onClick={(e) => handleSelectTime(e, time)}
                            />
                        })
                }
            </div>
            <div className="types-legend">
                <h5>{t('appointment.appointmentTypes')}:</h5>
                <ul>
                    <li>{getButtonIconByShortCode('V')} {t('appointment.appointmentTypeVideo')}</li>
                    <li>{getButtonIconByShortCode('P')} {t('appointment.appointmentTypeInPerson')}</li>
                    <li>{getButtonIconByShortCode('T')} {t('appointment.appointmentTypePhone')}</li>
                </ul>
            </div>
        </section>
    );
}

Calender.defaultProps = {
    dates: [],
    onSelectDate: null,
    onSelectTime: null,
    resetConsultant: null
}