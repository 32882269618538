import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Fragment} from "react";

export default function BookedSummary({selectedDate, selectedTime, givenPhone, selectedAppointmentTypeTitle}) {
    const navigate = useNavigate();
    const [t] = useTranslation('common');
    const { i18n } = useTranslation('home');


    console.log(i18n.language)

    let summaryText = '';

    switch (i18n.language) {
        case 'hu':
            summaryText = <Fragment>
                A {selectedAppointmentTypeTitle} időpontod
                <span style={{fontWeight: "bold"}}> {selectedDate.substr(6, 4)}.{selectedDate.substr(3, 2)}.{selectedDate.substr(0, 2)}</span> {t('summary.is')} <span>{selectedTime.substr(0, 5)}</span> {t('summary.at')}!
            </Fragment>

            break;
        default:
            summaryText = <Fragment>
                {t('summary.Your')} {selectedAppointmentTypeTitle}-{t('summary.Date')} {t('summary.is')}
                <span style={{fontWeight: "bold"}}> {selectedDate} {t('summary.at')} {selectedTime.substr(0, 6)}!</span>
            </Fragment>
    break;
    }

    return (
        <div className="center" style={{backgroundColor: "#f68c01", paddingTop: "2rem", color: "#ffffff"}}>

            <img style={{margin: "auto", display: "flex", objectFit: "cover"}} src="/logo192.png" alt="SOPHIE Logo" />

            <h1>{t('summary.Thanks')}!</h1>

            <div style={{fontSize: "20px", padding: "0 3rem 3rem 3rem"}}>
                {summaryText}<br />
                <p>{t('summary.ConfirmMsg')} ({ givenPhone !== '' ? 'SMS' : 'E-Mail' })</p>
            </div>

            <div style={{display: "flex", flexDirection: "column", padding: "0 3rem 3rem 3rem"}}>
                <div className="btn" onClick={() => navigate("/desire")}>{t('summary.BacktoHome')}</div>
                <div className="btn" onClick={() => navigate("/infos")}>{t('summary.ReadMore')}</div>
            </div>
        </div>
    )
}

BookedSummary.defaultProps = {
    selectedDate: '',
    selectedTime: ''
}